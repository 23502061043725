import * as React from "react"
import { Box, Flex, useColorModeValue, Text } from "@chakra-ui/react"

import { Link } from "gatsby"
import logoAdFusionMobile from "../images/logotypes/adFusion_logo_mobile.png"
import logoRedds from "../images/logotypes/creation-logo-redds.png"
import logoAgata from "../images/logotypes/creation-logo-agata.png"
import logoBiedronka from "../images/logotypes/creation-logo-biedronka.png"
import logoTMobile from "../images/logotypes/creation-logo-t-mobile.png"
import logoNetflix from "../images/logotypes/creation-logo-netflix.png"
import logoCeraVe from "../images/logotypes/creation-logo-cerave.png"
import logoFortuna from "../images/logotypes/creation-logo-fortuna.png"
import logoJordan from "../images/logotypes/creation-logo-jordan.png"
import logoTyskie from "../images/logotypes/creation-logo-tyskie.png"
import logoZywiec from "../images/logotypes/creation-logo-zywiec.png"
import logoWedel from "../images/logotypes/creation-logo-wedel.png"

const MobileItems = [
  {
    mobileName: "Biedronka",
    logo: logoBiedronka,
    to: "https://kreacje.spicymobile.com.pl/Biedronka+Loyalty/mwBqfN",
  },
  {
    mobileName: "Redd's",
    logo: logoRedds,
    to: "https://kreacje.spicymobile.com.pl/Redds+Kwiecien/aMgKCe",
  },
  {
    mobileName: "Agata Meble",
    logo: logoAgata,
    to: "https://kreacje.spicymobile.com.pl/Kampania+reklamowa+Agata+Kamapnia+TV+428072019/D5NpuR",
  },
  {
    mobileName: "T-Mobile",
    logo: logoTMobile,
    to: "https://kreacje.spicymobile.com.pl/TMobile+Kampania+Wizerunkowa/8YewuG",
  },
  {
    mobileName: "Netflix",
    logo: logoNetflix,
    to: "https://kreacje.spicymobile.com.pl/Netflix+Altered+Carbon/GqqYhd",
  },
  {
    mobileName: "CeraVe",
    logo: logoCeraVe,
    to: "https://kreacje.spicymobile.com.pl/Loreal+ceraveedoz/J0dpSW",
  },
  {
    mobileName: "Fortuna",
    logo: logoFortuna,
    to: "https://kreacje.spicymobile.com.pl/Fortuna+KSW/KQmWUR",
  },
  {
    mobileName: "Jordan",
    logo: logoJordan,
    to: "https://kreacje.spicymobile.com.pl/Jordan+TastePaste+Lipiec+2022/oe04s6",
  },
  {
    mobileName: "Tyskie",
    logo: logoTyskie,
    to: "https://kreacje.spicymobile.com.pl/Tyskie+UTC/aM8xIe",
  },
  {
    mobileName: "Grupa Żywiec",
    logo: logoZywiec,
    to: "https://kreacje.spicymobile.com.pl/Warka+BroBeer/x4d3ce",
  },
  {
    mobileName: "Wedel",
    logo: logoWedel,
    to: "https://kreacje.spicymobile.com.pl/Wedel+Ptasie+mleczko/LXRKH0",
  },
]

export default function MobileMenu({ onClose, ...rest }) {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full" }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        w="full"
        h="15%"
        alignItems="center"
        px="8"
        justifyContent="space-between"
        borderBottom="1px"
        borderBottomColor="blackAlpha.50"
      >
        <Box align="center" mx="12" borderRadius="lg" {...rest}>
          <img src={logoAdFusionMobile} />
        </Box>
      </Flex>
      <Box style={{ overflowY: "scroll" }} h="85%" px="8">
        {MobileItems.map(link => (
          <MobileNavItem key={link.mobileName} logo={link.logo} to={link.to}>
            <Text color={"blackAlpha.700"}>{link.mobileName}</Text>
          </MobileNavItem>
        ))}
      </Box>
    </Box>
  )
}

const MobileNavItem = ({ logo, children, to, ...rest }) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      to={to}
    >
      <Box
        align="center"
        mx="8"
        py="6"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="black"
        _hover={{
          bg: "blackAlpha.50",
          color: "black",
        }}
        {...rest}
      >
        <Box align="center" mx="10" borderRadius="lg" {...rest}>
          <img src={logo} />
        </Box>

        {children}
      </Box>
    </Link>
  )
}

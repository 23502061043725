import * as React from "react"
import { Show, Hide } from "@chakra-ui/react"
import GangSlodziakow from "./GangSlodziakow"
import MobileMenu from "../components/MobileMenu"
import SimpleSidebar from "../components/layout"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <>
    <Helmet>
      <title>AdFusion RichMedia</title>
    </Helmet>
    <Hide below="sm">
      <SimpleSidebar>
        <GangSlodziakow />
      </SimpleSidebar>
    </Hide>
    <Show below="md">
      <MobileMenu />
    </Show>
  </>
)

export default IndexPage
